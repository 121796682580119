var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"usagers","fluid":"","tag":"section","data-cy":"pageGestionEvenement"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-calendar","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('gestion_des_evenements.evenements'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('rechercher'),"hide-details":"","single-line":"","data-cy":"tfRechercherEvenement"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.listeEvenements,"data-cy":"dtEvenement","footer-props":{
                itemsPerPageText: _vm.$t('lignes_par_page'),
                itemsPerPageAllText: _vm.$t('tout'),
                pageText: ("{0}-{1} " + (_vm.$t('general.de')) + " {2}")
              }},scopedSlots:_vm._u([{key:"item.dateDebut",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaterDate(item.dateDebut))+" ")]}},{key:"item.dateFin",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaterDate(item.dateFin))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"px-2 ml-1 primary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onEditer(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"px-2 ml-1 warning",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onEffacer(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)}),(_vm.estAdmin)?_c('v-btn',{staticClass:"primary",attrs:{"data-cy":"btnCreerEvenement"},on:{"click":function($event){return _vm.onAjouter()}}},[_vm._v(" "+_vm._s(_vm.$t('gestion_des_evenements.edition_evenement.creer_nouvel_evenement.nouvel_evenement'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }